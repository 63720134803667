@import '~antd/dist/antd.css';

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}